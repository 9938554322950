import {Injectable} from '@angular/core';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';

import Bugsnag from '@bugsnag/js';

import {environment} from '@environments/environment';
import {UserStore} from 'wtc-shared';


@Injectable()
export class GlobalErrorHandler extends BugsnagErrorHandler {
  constructor(private userStore: UserStore) {
    super(Bugsnag.start({
      apiKey: environment.bugsnagKey, onError: event => {
        event.addMetadata('userInfo', {user: this.userStore.user$.value});
      }
    }));
  }

  handleError(error: any): any {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    super.handleError(error);
  }

}
