import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard, BRANDS_CONFIG, LoggedInGuard} from 'wtc-shared';

const routes: Routes = [
  {
    path: BRANDS_CONFIG.WATERCIRCLES.routesMap.profile,
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: BRANDS_CONFIG.WATERCIRCLES.routesMap.login,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: '**',
    redirectTo: BRANDS_CONFIG.WATERCIRCLES.routesMap.login
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
